<template>
  <div
    class="bg-agrogo-green-5 h-auto mt-5 pl-4 pr-5 pt-2 pb-1.5 md:pl-5 md:pr-16 lg:pl-9 md:pt-3.5 md:pb-3.5"
  >
    <div class="grid grid-cols-3">
      <div class="col-span-2 flex items-center lg:col-span-1">
        <img
          v-if="item.multimedia_property.length > 0"
          class="w-16 h-16 md:w-20 lg:w-20 md:h-20 lg:h-20 mr-2 transform transition-all hover:scale-110 cursor-pointer"
          :src="loadImg(item.multimedia_property)"
          @click.prevent="detailFarm(item)"
        />
        <img
          v-else
          class="w-16 h-16 md:w-20 lg:w-20 md:h-20 lg:h-20 mr-2 transform transition-all hover:scale-110 cursor-pointer"
          src="@/assets/images/logoicono.png"
          @click.prevent="detailFarm(item)"
        />
        <div class="grid grid-cols-1">
          <p
            class="text-white text-xs font-dinpro font-normal text-left md:text-sm xl:text-base opacity-80"
          >
            <span class="text-agrogo-yellow-1">ID# </span> {{ item.id }}
          </p>
          <a
            class="text-white text-xs md:text-base lg:text-lg font-nexa font-normal text-left cursor-pointer"
            @click.prevent="detailFarm(item)"
          >
            {{ item.area.value }} {{ item.area.unit }}
          </a>
          <a
            class="text-white text-xs md:text-base lg:text-lg font-nexa font-normal text-left cursor-pointer"
            @click.prevent="detailFarm(item)"
          >
            {{ item.municipalities.name }}, {{ item.departments.name }}
          </a>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <div class="flex flex-row items-center justify-end">
          <!-- <div v-else>
          <div
            class="text-white font-dinpro font-medium lg:font-bold text-xs md:text-sm lg:text-base flex items-center justify-end cursor-pointer"
          >
            {{ item.state.name }}
            <div class="pl-2" v-if="item.state.name == 'INCOMPLETA'">
              <div
                class="flex items-center justify-center w-4 h-4 bg-agrogo-red-1 rounded-full"
              >
                <span class="icon-uniE94C text-white text-3xs"></span>
              </div>
            </div>
            <div class="pl-2" v-if="item.state.name == 'PUBLICADA'">
              <span class="icon-hecho text-agrogo-yellow-1 text-xs"></span>
            </div>
          </div>
        </div> -->
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-1 justify-items-end">
          <div class="flex flex-col pb-2">
            <span
              class="font-dinpro font-medium text-2xs text-agrogo-yellow-1 text-left"
              >Precio Actual</span
            >
            <p
              class="text-white text-base font-dinpro font-normal text-left flex lg:hidden md:text-base"
            >
              <span
                class="icon-signo-pesos text-agrogo-yellow-1 mt-1 mr-1"
              ></span>
              {{ formatThousand(item.price.value) }}
            </p>
            <span
              class="font-dinpro font-normal text-2xs text-white opacity-60 text-right"
              >(Pesos Colombianos)</span
            >
          </div>

          <div
            @click.prevent="edit(item.slug)"
            class="flex items-center justify-end cursor-pointer"
            v-if="item.state.id == 2"
          >
            <label
              for="edit-finca"
              class="text-white text-xs font-nexa font-normal justify-end mr-2.5 hover:text-agrogo-yellow-1 md:text-base"
            >
              Completar
            </label>
            <span
              class="icon-edit text-agrogo-yellow-1 text-xs transform transition-all hover:scale-110 md:text-base"
              id="edit-finca"
            ></span>
          </div>
          <div
            class="flex items-center justify-end"
            v-else-if="
              item.favorite == 0 && (item.state.id == 1 || item.state.id == 5)
            "
          >
            <label
              :for="item.id + 'mobile'"
              class="text-white cursor-pointer text-xs font-nexa font-normal justify-end mr-2.5 hover:text-agrogo-yellow-1 md:text-base"
            >
              Vendido
            </label>
            <div class="loader" v-if="item.id == cardId && isBusy" />
            <input
              v-else
              :id="item.id + 'mobile'"
              :true-value="true"
              :false-value="false"
              v-model="isCheckedFarm"
              type="checkbox"
              class="custom-checkbox-test lg:mb-1"
              @change="saleFarm(item.id)"
            />
          </div>
          <div
            v-else-if="item.favorite == 1 && item.state.id == 5"
            class="flex items-center justify-end"
            @click.prevent="deleteFavorite(item.id)"
            :disabled="isBusy2"
          >
            <label
              for="delete_finca_1"
              class="text-white text-xs font-nexa font-normal justify-end mr-2.5 hover:text-agrogo-yellow-1 md:text-base cursor-pointer"
            >
              Eliminar
            </label>
            <div class="loader" v-if="item.id == cardId && isBusy2" />
            <span v-else class="icon-heart text-agrogo-yellow-1"></span>
          </div>
          <!-- <div class="flex items-center justify-end lg:justify-start lg:pl-3">
          <label
            for="delete_finca_1"
            class="text-white text-xs font-nexa font-normal justify-end mr-2.5 hover:text-agrogo-yellow-1 md:text-base cursor-pointer"
          >
            Borrar
          </label>
          <span
            class="icon-delete text-agrogo-yellow-1 transform transition-all hover:scale-110 lg:mb-1 cursor-pointer"
            id="delete_finca_1"
          ></span>
        </div> -->
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 pt-2 gap-2">
      <div class="flex items-center justify-start flex-wrap space-x-1">
        <span
          v-for="category in item.subcategory"
          :key="category.id"
          class="text-agrogo-yellow-1 text-xs text-center"
          :class="category.icon"
        ></span>
      </div>

      <div class="flex items-center justify-end lg:justify-center">
        <div
          class="text-white mr-2 font-dinpro font-medium lg:font-bold text-xs md:text-sm lg:text-base"
        >
          {{ textState(item) }}
        </div>
        <div :class="backgroundState(item)">
          <span class="text-xs" :class="iconState(item)"></span>
        </div>
      </div>
      <div
        class="grid place-content-center place-items-end"
        v-if="item.priority.length > 0"
      >
        <span class="text-white font-nexa font-normal text-xs">
          {{ textFarm(item.priority[0]) }}
        </span>
        <div>
          <p class="text-white size-star space-x-px">
            <span
              class="icon-star2"
              v-for="(start, i) in starts"
              :key="i"
              :class="{
                'text-agrogo-yellow-1': start.isActive,
                'text-white': !start.isActive,
              }"
            />
          </p>
        </div>
      </div>
      <div
        @click.prevent="standOutFarm(item.slug)"
        class="flex items-center justify-end cursor-pointer"
        v-if="
          item.priority.length > 0 && item.priority[0].id == 6 && !isPreLaunch
        "
      >
        <button for="edit-finca" class="btn-login h-8 w-28">Destacar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { API_ROUTES } from "@/config";
import { mapState } from "vuex";

export default {
  emits: ["saleFarm", "deleteFavorite"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isBusy2: {
      type: Boolean,
      default: false,
    },
    cardId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      starts: [
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
      isCheckedFarm: false,
    };
  },
  watch: {
    item() {
      const me = this;
      me.starts.forEach((el) => (el.isActive = false));
      me.fillStart(me.item);
      me.checkedFarm();
    },
  },
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  methods: {
    //DA FORMATO DE PUNTOS DE MIL
    formatThousand(number) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        currency: "COP",
        minimumFractionDigits: 0,
      });
      number = formatterPeso.format(number);
      return number;
    },
    //CONCATENA LA URL DE LA IMAGEN A LA URL BASE
    loadImg(data) {
      var url = "@/assets/images/logoicono.png";
      data.forEach((el) => {
        //TIPO 1 ES IMAGENES
        if (el.type == 1) {
          url = `${API_ROUTES.image.get}${el.media.small_url}`;
        }
      });
      return url;
    },
    detailFarm(item) {
      const me = this;
      if (!me.isPreLaunch)
        me.$router.push({ name: "FarmDetail", params: { slug: item.slug } });
    },
    standOutFarm(slug) {
      const me = this;
      me.$router.push({ name: "stand-out", params: { slug: slug } });
    },
    edit(slug) {
      const me = this;
      me.$router.push({ name: "completr-publicar", params: { slug: slug } });
    },
    textState(item) {
      let text = "";

      if (item.favorite == 1 && item.state.id == 1) {
        text = "Favorita";
      } else if (item.state.id == 2) {
        text = "Incompleta";
      } else if (item.state.id == 1 && item.favorite == 0) {
        text = "Publicada";
      } else if (item.state.id == 3) {
        text = "Pendiente de aprobación";
      } else if (item.state.id == 5) {
        text = "Vendido";
      } else if (item.state.id == 6) {
        text = "En revisión";
      }

      return text;
    },
    iconState(item) {
      let icon = "";

      if (item.favorite == 1 && item.state.id == 1) {
        icon = "text-agrogo-yellow-1 icon-heart";
      } else if (item.state.id == 2) {
        icon = "icon-uniE94C text-white text-3xs";
      } else if (item.state.id == 1 && item.favorite == 0) {
        icon = "text-agrogo-yellow-1 icon-hecho";
      } else if (item.state.id == 3) {
        icon = "";
      } else if (item.state.id == 5) {
        icon = "";
      }

      return icon;
    },
    backgroundState(item) {
      let background = "";

      if (item.favorite == 1 && item.state.id == 1) {
        background = "";
      } else if (item.state.id == 2) {
        background =
          "flex items-center justify-center w-4 h-4 bg-agrogo-red-1 rounded-full";
      } else if (item.state.id == 1 && item.favorite == 0) {
        background = "";
      } else if (item.state.id == 3) {
        background = "";
      } else if (item.state.id == 5) {
        background = "";
      }

      return background;
    },
    fillStart(item) {
      const me = this;
      if (item.priority.length > 0) {
        if (item.priority[0].id == 1 || item.priority[0].id == 2) {
          // Super
          me.starts.forEach((el) => {
            el.isActive = true;
          });
        } else if (item.priority[0].id == 3) {
          // Plus
          me.starts.forEach((el, index) => {
            if (index <= 3) {
              el.isActive = true;
            }
          });
        } else if (item.priority[0].id == 4) {
          // Alto
          me.starts.forEach((el, index) => {
            if (index <= 2) {
              el.isActive = true;
            }
          });
        } else if (item.priority[0].id == 5) {
          // Medio
          me.starts.forEach((el, index) => {
            if (index <= 1) {
              el.isActive = true;
            }
          });
        } else {
          // Básico
          me.starts.forEach((el, index) => {
            if (index <= 0) {
              el.isActive = true;
            }
          });
        }
      }
      // Super 5 estrellas1
      // Plus 4 estrellas2
      // Alto 3 3
      // Medio 2 4
      // Basico 1 5
    },
    textFarm(item) {
      let text = "";
      if (item.id == 1 || item.id == 2) {
        text = "Super";
      } else {
        text = item.name;
      }
      return text;
    },
    checkedFarm() {
      const me = this;
      let state = false;

      me.item.state.id == 5 ? (state = true) : (state = false);

      me.isCheckedFarm = state;
    },
    saleFarm(id) {
      const me = this;
      me.$emit("saleFarm", id);
    },
    deleteFavorite(id) {
      const me = this;
      me.$emit("deleteFavorite", id);
    },
  },
  mounted() {
    const me = this;
    me.fillStart(me.item);
    me.checkedFarm();
  },
};
</script>
